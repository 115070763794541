import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CollapsePanelComponent } from './collapse-panel.component';

@NgModule({
  declarations: [CollapsePanelComponent],
  imports: [CommonModule, DragDropModule, MatIconModule, MatButtonModule],
  exports: [CollapsePanelComponent],
})
export class CollapsePanelModule {}
