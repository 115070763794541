import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ApplicationStorageService } from '@logic-suite/shared/storage';

/**
 * @deprecated use lib-collapse-panel instead.
 */
@Component({
    selector: 'lib-collapse-panel-old',
    templateUrl: './collapse-panel.component.html',
    styleUrls: ['./collapse-panel.component.scss'],
    standalone: false
})
export class CollapsePanelComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() hideOn: string | null = '(min-width: 680px)';
  @Input() openDirection: 'left' | 'right' = 'right';
  @Input() resizable = true;
  @Output() opened = new EventEmitter<boolean>();
  @ViewChild('dragHandle', { static: false }) dragHandle!: ElementRef;
  @ViewChild('content', { static: true }) content!: ElementRef;

  // Content properties
  @HostBinding('class.visible')
  @Input()
  open = true;

  @HostBinding('style.--content-panel-width')
  width = '300px';

  get resizeEl() {
    return this.el.nativeElement;
  }

  get dragHandleEl(): HTMLElement {
    return this.dragHandle?.nativeElement;
  }

  get contentEl() {
    return this.content.nativeElement;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private ngZone: NgZone,
    private el: ElementRef,
    private store: ApplicationStorageService,
  ) {}

  ngOnInit() {
    if (this.hideOn) {
      this.breakpointObserver.observe(this.hideOn).subscribe((visible) => {
        this.open = visible.matches;
        this.opened.emit(this.open);
      });
    }
    // Prefetch last set width on init
    this.setWidth(+this.store.getItem('collapsePanel.width', Number(this.width.slice(0, -2))));
  }

  ngAfterViewInit() {
    this.setAllHandleTransform();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.open) {
      this.toggleContent(this.open ? 'open' : 'close');
    }
  }

  setAllHandleTransform() {
    const rect = this.resizeEl.getBoundingClientRect();
    const dragRect = this.dragHandleEl?.getBoundingClientRect();
    if (dragRect && this.openDirection === 'right') {
      this.dragHandleEl.style.transform = `translate3d(${rect.width - dragRect.width}px, 0, 0)`;
    }
    window.dispatchEvent(new Event('resize'));
  }

  ngOnDestroy() {
    this.breakpointObserver.ngOnDestroy();
  }

  toggleContent(openClose?: 'open' | 'close') {
    this.open = openClose != null ? openClose === 'open' : !this.open;
    this.opened.emit(this.open);
    setTimeout(() => this.setAllHandleTransform());
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500); // Dispatch after animation completes
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dragStart() {
    this.dragHandleEl?.classList.add('dragging');
  }

  dragStop() {
    this.ngZone.runOutsideAngular(() => {
      this.dragHandleEl?.classList.remove('dragging');
      const rect = this.resizeEl.getBoundingClientRect();
      const dragRect = this.dragHandleEl?.getBoundingClientRect();
      if (dragRect) {
        let width = rect.width - dragRect.width;
        if (width < 150 && !!this.open) {
          width = 200;
          this.toggleContent('close');
        }
        this.setWidth(width);
      }
    });
  }

  dragMove() {
    this.ngZone.runOutsideAngular(() => {
      const dragRect = this.dragHandleEl?.getBoundingClientRect();
      if (dragRect) {
        const targetRect = this.resizeEl.getBoundingClientRect();
        if (this.openDirection === 'right') {
          this.setWidth(dragRect.left - targetRect.left + dragRect.width);
        } else {
          this.setWidth(dragRect.right - targetRect.right + dragRect.width);
        }
      }
    });
  }

  setWidth(width: number) {
    if (this.resizable) {
      this.width = `${width}px`;
      this.store.setItem('collapsePanel', { width });
      setTimeout(() => this.setAllHandleTransform());
    }
  }

  resetSize() {
    this.width = '300px';
    this.store.removeItem('collapsePanel');
    setTimeout(() => this.setAllHandleTransform());
  }
}
