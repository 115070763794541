<aside #content>
  <ng-content></ng-content>
</aside>

<span
  #dragHandle
  *ngIf="resizable"
  cdkDragLockAxis="x"
  class="dragHandle"
  cdkDrag
  (cdkDragStarted)="dragStart()"
  (cdkDragReleased)="dragStop()"
  (cdkDragMoved)="dragMove()"
  (dblclick)="resetSize()"
></span>

<!-- Visibility toggler -->
<button
  type="button"
  mat-icon-button
  class="content-toggle screen-only"
  aria-label="Toggle content visibility"
  (click)="toggleContent()"
>
  <mat-icon>arrow_right</mat-icon>
</button>
